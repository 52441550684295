import React, { Fragment, useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";

const LogoImage = ({ logo, imgProps = {} }) => {
  const originalWidth = 179;
  const originalHeight = 79;
  const aspectRatio = originalWidth / originalHeight;

  const [dimensions, setDimensions] = useState({
    width: originalWidth,
    height: originalHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 575) {
        const newHeight = 60;
        const newWidth = newHeight * aspectRatio;
        setDimensions({ width: newWidth, height: newHeight });
      } else {
        setDimensions({ width: originalWidth, height: originalHeight });
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      <Link href={"/"}>
        <a href={"#"}>
          <div
            style={{
              position: "relative",
              width: dimensions.width,
              height: dimensions.height,
            }}
          >
            <Image
              src={`/assets/images/icon/${logo ? logo : "logo.png"}`}
              alt="Logo"
              width={dimensions.width}
              height={dimensions.height}
              {...imgProps}
            />
          </div>
        </a>
      </Link>
    </Fragment>
  );
};

export default LogoImage;
