import React, { useState, useEffect, useContext } from "react";
import { useRouter } from "next/router";
import { useMediaQuery } from "react-responsive";
import { BiSearch } from "react-icons/bi";
import { MENUITEMS } from "../../constant/menu";
import { AuthContext } from "../../../helpers/auth/AuthContext";
import { isEmpty } from "../../../helpers/utils/helpers";
import vars from "../../../helpers/utils/vars";
import ProduceModal from "../../modals/ProduceModal";
import SearchModal from "../../modals/SearchModal";

const NavBarMobile = () => {
  const authContext = useContext(AuthContext);
  const onAuthModalsTriggered = authContext.onAuthModalsTriggered;
  const isAuthenticated = authContext.isAuthenticated;
  const user = authContext.user;
  const onTarget = authContext.onTarget;
  const isTabletOrMobile = useMediaQuery({
    query: vars.MEDIA_QUERIES.TABLET_MOBILE,
  });

  const [isProduceModal, setIsProduceModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const router = useRouter();

  const [activeNavIndex, setActiveNavIndex] = useState(0);

  useEffect(() => {
    const currentPath = router.pathname;
    const index = MENUITEMS.findIndex((item) => item.path === currentPath);
    setActiveNavIndex(index !== -1 ? index : 0);
  }, [router.pathname]);

  const openMblNav = (menu) => {
    if (menu.title === "MEMBERSHIP PLANS") {
      if (isAuthenticated) {
        if (user.role === "seller") {
          router.push({
            pathname: "/seller/account",
            query: { active: "plan" },
          });
        } else if (user.role === "buyer") {
          router.push({
            pathname: "/buyer/account",
            query: { active: "plan" },
          });
        }
      } else {
        onTarget({
          pathname: "/account",
          query: { active: "plan" },
        });
        onAuthModalsTriggered("user_type");
      }
    } else if (menu.title === "PRODUCE") {
      setIsProduceModal(true);
    } else if (menu.title === "REPORTS") {
      if (!isAuthenticated) {
        onTarget(menu.path);
        onAuthModalsTriggered("login");
      } else if (
        isEmpty(user.subs) ||
        user.subs?.membership_type?.toLowerCase() === "blue"
      ) {
        onAuthModalsTriggered("Permission", "", {
          backButton: true,
          message: "Oops. You need to upgrade your subscription to view these!",
        });
      } else {
        router.push(menu.path);
      }
    } else {
      router.push(menu.path);
    }
  };

  return (
    <div className="main-navbar">
      <div id="mainnav">
        <ul className="nav-menu">
          {isTabletOrMobile && (
            <li>
              <div
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                  border: "1px solid gray",
                  borderRadius: 25,
                  maxWidth: "85%",
                  cursor: "pointer",
                }}
                className="nav-link mt-3 mb-4 ml-3 px-3 py-2 d-flex align-items-center"
                onClick={() => setSearchModal(true)}
              >
                <BiSearch className="mr-3" />
                <span>Search</span>
              </div>
            </li>
          )}
          {MENUITEMS.filter((x) => {
            if (authContext.isAuthenticated) {
              if (x.path !== "/about-us") return true;
              return false;
            } else {
              return true;
            }
          }).map((menuItem, i) => {
            return (
              <li key={i} style={{ display: "block" }}>
                <a
                  href="#"
                  className={`nav-link ${i === activeNavIndex ? "active" : ""}`}
                  onClick={() => {
                    openMblNav(menuItem);
                    setActiveNavIndex(i);
                  }}
                  style={{
                    color: i === activeNavIndex ? "#20963d" : "#313131",
                  }}
                >
                  {menuItem.title}
                </a>
              </li>
            );
          })}
          <li>
            <a
              href="#"
              className={`nav-link ${
                activeNavIndex === MENUITEMS.length ? "active" : ""
              }`}
              onClick={() => {
                router.push({
                  pathname: `/${
                    user.role === "seller" ? "seller" : "buyer"
                  }/dashboard`,
                  query: { active: "plan" },
                });
                setActiveNavIndex(MENUITEMS.length);
              }}
              style={{
                color:
                  activeNavIndex === MENUITEMS.length ? "#20963d" : "#313131",
              }}
            >
              {user.role === "seller" ? "SELLER" : "BUYER"} DASHBOARD
            </a>
          </li>
        </ul>
      </div>

      <ProduceModal
        isShow={isProduceModal}
        onToggle={(val) => setIsProduceModal(val)}
      />
      <SearchModal
        isShow={searchModal}
        onToggle={(val) => setSearchModal(val)}
      />
    </div>
  );
};

export default NavBarMobile;

// import React, { useState, useEffect, useContext } from "react";
// import { useTranslation } from "react-i18next";
// import { useRouter } from "next/router";
// import { useMediaQuery } from "react-responsive";
// import { BiSearch } from "react-icons/bi";
// import { MENUITEMS } from "../../constant/menu";
// import { AuthContext } from "../../../helpers/auth/AuthContext";
// import { isEmpty } from "../../../helpers/utils/helpers";
// import vars from "../../../helpers/utils/vars";
// import ProduceModal from "../../modals/ProduceModal";
// import SearchModal from "../../modals/SearchModal";

// const NavBarMobile = () => {
//   const { t } = useTranslation();
//   const authContext = useContext(AuthContext);
//   const onAuthModalsTriggered = authContext.onAuthModalsTriggered;
//   const isAuthenticated = authContext.isAuthenticated;
//   const user = authContext.user;
//   const onTarget = authContext.onTarget;
//   const isTabletOrMobile = useMediaQuery({
//     query: vars.MEDIA_QUERIES.TABLET_MOBILE,
//   });

//   const [isProduceModal, setIsProduceModal] = useState(false);
//   const [searchModal, setSearchModal] = useState(false);
//   const router = useRouter();

//   const [activeNavIndex, setActiveNavIndex] = useState(0);

//   useEffect(() => {
//     const currentPath = router.pathname;
//     const index = MENUITEMS.findIndex((item) => item.path === currentPath);
//     setActiveNavIndex(index !== -1 ? index : 0);
//   }, [router.pathname]);

//   const openMblNav = (menu) => {
//     if (menu.title === "MEMBERSHIP PLANS") {
//       if (isAuthenticated) {
//         if (user.role === "seller") {
//           router.push({
//             pathname: "/seller/account",
//             query: { active: "plan" },
//           });
//         } else if (user.role === "buyer") {
//           router.push({
//             pathname: "/buyer/account",
//             query: { active: "plan" },
//           });
//         }
//       } else {
//         onTarget({
//           pathname: "/account",
//           query: { active: "plan" },
//         });
//         onAuthModalsTriggered("user_type");
//       }
//     } else if (menu.title === "PRODUCE") {
//       setIsProduceModal(true);
//     } else if (menu.title === "REPORTS") {
//       if (!isAuthenticated) {
//         onTarget(menu.path);
//         onAuthModalsTriggered("login");
//       } else if (
//         isEmpty(user.subs) ||
//         user.subs?.membership_type?.toLowerCase() === "blue"
//       ) {
//         onAuthModalsTriggered("Permission", "", {
//           backButton: true,
//           message: "Oops. You need to upgrade your subscription to view these!",
//         });
//       } else {
//         router.push(menu.path);
//       }
//     } else {
//       router.push(menu.path);
//     }
//   };

//   return (
//     <div className="main-navbar">
//       <div id="mainnav">
//         <ul className="nav-menu">
//           {isTabletOrMobile && (
//             <li>
//               <div
//                 style={{
//                   boxShadow:
//                     "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
//                   border: "1px solid gray",
//                   borderRadius: 25,
//                   maxWidth: "85%",
//                   cursor: "pointer",
//                 }}
//                 className="nav-link mt-3 mb-4 ml-3 px-3 py-2 d-flex align-items-center"
//                 onClick={() => setSearchModal(true)}
//               >
//                 <BiSearch className="mr-3" />
//                 <span>Search</span>
//               </div>
//             </li>
//           )}
//           {MENUITEMS.filter((x) => {
//             if (authContext.isAuthenticated) {
//               if (x.path !== "/about-us") return true;
//               return false;
//             } else {
//               return true;
//             }
//           }).map((menuItem, i) => {
//             return (
//               <li key={i} style={{ display: "block" }}>
//                 <a
//                   href="#"
//                   className={`nav-link ${i === activeNavIndex ? "active" : ""}`}
//                   onClick={() => {
//                     openMblNav(menuItem);
//                     setActiveNavIndex(i);
//                   }}
//                   style={{
//                     color: i === activeNavIndex ? "#20963d" : "#313131",
//                   }}
//                 >
//                   {t(menuItem.title)}
//                 </a>
//               </li>
//             );
//           })}
//           <li>
//             <a
//               href="#"
//               className={`nav-link ${
//                 activeNavIndex === MENUITEMS.length ? "active" : ""
//               }`}
//               onClick={() => {
//                 router.push({
//                   pathname: `/${
//                     user.role === "seller" ? "seller" : "buyer"
//                   }/dashboard`,
//                   query: { active: "plan" },
//                 });
//                 setActiveNavIndex(MENUITEMS.length);
//               }}
//               style={{
//                 color:
//                   activeNavIndex === MENUITEMS.length ? "#20963d" : "#313131",
//               }}
//             >
//               {user.role === "seller" ? "SELLER" : "BUYER"} DASHBOARD
//             </a>
//           </li>
//         </ul>
//       </div>

//       <ProduceModal
//         isShow={isProduceModal}
//         onToggle={(val) => setIsProduceModal(val)}
//       />
//       <SearchModal
//         isShow={searchModal}
//         onToggle={(val) => setSearchModal(val)}
//       />
//     </div>
//   );
// };

// export default NavBarMobile;
